import CoreApi from '@/api/core'
import TagModel from '@/model/Tag.js'
import GeneeaTagModel from '@/model/GeneeaTagModel.js'
import Config from '@/config'
import TagFilterService from '@/services/tag/TagFilterService'

const API_NAME = '/tag'
const SEARCH_API_NAME = '/tag/suggest-tag'
const API_NAME_GENEEA = '/geneeaTag'

const state = {
  error: null,
  formErrors: {},
  detail: TagModel,
  geneeaDetail: GeneeaTagModel,
  list: [],
  listGeneea: [],
  totalCount: 0,
  page: 1,
  filterQuery: '',
  filter: {
    id: '',
    title: '',
    titleExact: ''
  }
}

function handleApiError (store, error) {
  if (error.response && error.response.status === 400) {
    if (error.response.data.error.includes('already exists')) {
      store.commit('SET_FORM_ERRORS', { name: error.response.data.error, type: 'duplicate_name' })
    }
  } else {
    store.commit('setError', error)
  }
  store.commit('TOGGLE_LOADING', null, { root: true })
  throw error
}

const mutations = {
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeListGeneea (state, responseData) {
    state.listGeneea = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
  },
  storeGeneeaDetail (state, responseData) {
    state.geneeaDetail = responseData
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  setFilterQuery (state, filterQuery) {
    state.filterQuery = filterQuery
  },
  SET_FORM_ERRORS (state, errors) {
    state.formErrors = errors
  }
}

const actions = {
  // *** tag actions ***
  async fetch (store) {
    try {
      store.commit('TOGGLE_LOADING', null, { root: true })
      const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
      const url = API_NAME +
        '?limit=' + Config.defaults.list.limit +
        '&offset=' + offset +
        '&order[id]=asc' + TagFilterService.buildFilterQuery(store.state.filter)
      const response = await CoreApi().get(url)
      if (!store.state.filter.titleExact) {
        store.commit('storeList', response.data)
      }
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      store.commit('TOGGLE_LOADING', null, { root: true })
    }
  },
  async fetchOne (store, id) {
    try {
      const response = await CoreApi().get(API_NAME + '/' + id)
      store.commit('storeDetail', response.data)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async fetchByTitle ({ commit }, { query, view = 'article' }) {
    if (query) {
      await CoreApi().get(`${SEARCH_API_NAME}?view=${view}&search=${query}`)
        .then(res => {
          commit('storeList', { data: res.data, totalCount: 0 })
        })
        .catch(error => console.error(error))
    }
  },
  fetchByIds (store, ids) {
    const url = API_NAME + '?view=minimal&limit=1000&filter_in[id]=' + ids.join(',')
    return CoreApi().get(url)
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(error => {
        console.error(error)
      })
  },
  async loadTagsByIds (store, ids) {
    if (ids?.length > 0) {
      try {
        const response = await CoreApi().get(`${API_NAME}?filter_in[id]=${ids}`)
        store.commit('storeList', response.data)
        return response.data
      } catch (e) {
        console.error(e)
      }
    }
  },
  async loadGeneeaTagsByIds (store, ids) {
    if (ids?.length > 0) {
      try {
        const response = await CoreApi().get(`${API_NAME_GENEEA}?filter_in[id]=${ids}`)
        store.commit('storeListGeneea', response.data)
        return response.data
      } catch (e) {
        console.error(e)
      }
    }
  },
  async create (store, record) {
    try {
      const response = await CoreApi().post(API_NAME, JSON.stringify(record))

      if (response.status === 201) {
        store.commit('storeDetail', response.data)
        store.commit('setError', null)
        return response.data
      } else {
        store.commit('setError', 'Error')
        return Promise.reject(new Error('Error'))
      }
    } catch (error) {
      const errorStatus = error.response?.status
      if (errorStatus === 500) {
        store.commit('setError', errorStatus)
      } else {
        handleApiError(store, error)
      }
      return Promise.reject(error)
    }
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  // *** geneea tag actions ***
  fetchGeneeaList (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME_GENEEA +
      '?limit=' + Config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=asc' + TagFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeListGeneea', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchByTitleGeneea ({ commit }, { query, view = 'article' }) {
    await CoreApi().get(API_NAME_GENEEA + `?view=${view}&filter_contains[title]=${query}`)
      .then(res => {
        commit('storeListGeneea', res.data)
      })
      .catch(error => console.error(error))
  },
  async fetchOneGeneea (store, id) {
    try {
      const response = await CoreApi().get(API_NAME_GENEEA + '/' + id)
      store.commit('storeGeneeaDetail', response.data)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async deleteGeneeaRecord (store, record) {
    return await CoreApi().delete(API_NAME_GENEEA + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  // universal action
  async update (store, { record, tagType = 'tag' }) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    return await CoreApi().put((tagType === 'geneea' ? `${API_NAME_GENEEA}/${record.id}` : `${API_NAME}/${record.id}`), JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          if (tagType === 'tag') {
            store.commit('storeDetail', response.data)
          }
          if (tagType === 'geneea') {
            store.commit('storeGeneeaDetail', response.data)
          }
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        const errorStatus = error.response.status
        if (errorStatus === 500) {
          store.commit('setError', errorStatus)
          store.commit('TOGGLE_LOADING', null, { root: true })
        } else {
          handleApiError(store, error)
        }
      })
  },
  async fetchArticles ({ commit }, ids) {
    if (ids && ids.length) {
      const url = `/article?view=minimal&filter_eq[setting.status]=published&filter_in[id]=${ids.join(',')}`
      const response = await CoreApi().get(url)
      if (response.status === 200) {
        // commit('storeArticles', response.data.data)
        return response.data.data
      }
      return []
    } else {
      throw new Error('Invalid document IDs')
    }
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  geneeaDetail (state) {
    return state.geneeaDetail
  },
  list (state) {
    return state.list
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  filterQuery (state) {
    return state.filterQuery
  },
  listGeneea (state) {
    return state.listGeneea
  },
  tagsWithRecipeCount (state) {
    return state.list.map(tag => {
      tag.title = `${tag.title} (${tag.recipeCount})`
      return tag
    })
  },
  tagsWithGalleryCount (state) {
    return state.list.map(tag => {
      tag.title = `${tag.title} (${tag.galleryCount})`
      return tag
    })
  },
  formErrors: state => state.formErrors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
