const messages = {
  copy: {
    first: 'HP blok',
    last: 'byl zkopírován'
  },
  list: {
    id: 'Id',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    number_of_items: 'Počet položek',
    external_fallback: 'Externí',
    internal_fallback: 'Článek',
    fallback_article_types: 'Typy záložních článků',
    fallback_article_flags: 'Příznaky záložních článků',
    hidden: 'Skryto',
    position: 'Pozice',
    created_at: 'Vytvořeno dne',
    modified_at: 'Upraveno dne',
    created_by: 'Vytvořil'
  },
  filter: {
    site: 'Stránka',
    type: 'Typ',
    title: 'Název',
    identifier: 'Identifikátor',
    id: 'Id',
    search: 'Vyhledávání'
  },
  id: 'Id',
  hidden: 'Skryto',
  type: 'Typ',
  title: 'Název',
  identifier: 'ID',
  site: 'Stránka',
  sites_and_subsites: 'Stránky/Podstránky',
  category: 'Kategorie',
  number_of_items: 'Počet položek',
  position: 'Pozice',
  daysLimit: 'Limit dnů pro dotaz',
  display_position: 'Pozice zobrazení',
  disabled_positions: 'Zakázané pozice',
  fallback_positions: 'Záložní pozice',
  external_fallback: 'Povolit externí zálohu',
  internal_fallback: 'Povolit interní zálohu',
  fallback_site: 'Stránka',
  fallback_rubrics: 'Rubriky',
  fallback_tags: 'Redakční tagy',
  geneea_tags: 'Geneea tagy',
  fallback_article_types: 'Typy článků (bude použito logické NEBO)',
  fallback_article_flags: 'Příznaky článků (bude použito logické NEBO)',
  fallback_article_settings: 'Ostatní',
  fallback_categories: 'Kategorie',
  source: 'Zdroj',
  content: 'Obsah',
  content_block_type_value: {
    article: 'Články',
    video_article: 'Video článek',
    video: 'Video',
    topics: 'Témata',
    specials: 'Speciály',
    serials: 'Seriály'
  },
  main_settings: 'Hlavní nastavení',
  fallback_settings: 'Nastavení obsahu',
  gallery: 'Galerie',
  pr: 'Pouze PR články',
  items: 'Položky',
  total: 'Celkově',
  regional_min: 'Regional min',
  sport_max: 'Sport max',
  author: 'Autor',
  internal: 'Interní',
  external: 'Externí',
  flags: 'Flagy',
  isHomepage: 'Homepage',
  article: 'Článek',
  cbArticleType: 'Typ Content Blocku',
  buttonName: 'Název tlačítka',
  buttonUrl: 'URL adresa tlačítka',
  contentBlockFlags: {
    video: 'Video',
    imageGallery: 'Fotogalerie',
    podcast: 'Podcast',
    quiz: 'Kvíz',
    poll: 'Anketa',
    discussion: 'Diskuze'
  },
  staticContentBlock: {
    change: 'Změnit',
    delete: 'Smazat',
    select: 'Vybrat'
  }
}

export default messages
