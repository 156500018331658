<template>
  <div
    class="input-area"
    :class="{ 'form-group': !disableFormGroup }"
  >
    <label class="input-area__label" :class="{ 'input-area__label--change-checkbox': showChangeCheckbox }" :for="id" v-if="!noLabel">
      <slot></slot>
      <span :class="{ 'input-area__text-error': error }" v-html="label"></span>
      <span v-if="required" class="required">*</span>
      <app-tooltip v-if="tooltip.length" :title="tooltip"></app-tooltip>
    </label>
    <div :class="{
      'input-group': true,
      'input-area__group': true,
      'input-area__group--label-right': rightLabel,
      'input-area__group--counter': showCounter,
      'input-area__group--change-checkbox': showChangeCheckbox
    }">
      <i v-if="magnifier" class="fa fa-search magnifier-icon"></i>
      <Checkbox
        v-if="showChangeCheckbox"
        :id="`change-${id}`"
        :value="changeCheckbox"
        @input="$emit('change-checked', $event)"
        class="input-area__change-checkbox"
      />
      <input
        :type="type"
        :id="id"
        :ref="id"
        class="input-area__input"
        :class="{ 'input-area__input--error': error,  'input-area__input--enabled': !disabled, 'magnifier-input': magnifier }"
        @input="handleInput"
        @blur="[$emit('blur', validate($event.target.value)), isInputActive = false]"
        @focus="isInputActive = true"
        :value="displayedValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :readonly="readonly"
        :max="max"
        :min="min"
        :step="step"
        :data-test="dataAttr ? dataAttr : id"
      />
      <div
        v-if="showCheckmark"
        class="input-area__check"
      >
        <i class="fas fa-check-circle input-area__check-icon"></i>
      </div>
      <div class="input-group-append" v-if="showCounter">

        <span
          v-if="showMaxLength"
          class="input-group-append__label"
        >{{ $t('input_char_count') }}</span>
        <span class="input-group-text text-center">
          <template v-if="value">{{ value.length + valueSuffix.length }}</template>
          <template v-if="!value">0</template>
          <template v-if="showMaxLength">{{ $t('input_char_delimiter') }} {{ maxAvailableLength }}</template>
        </span>
      </div>
      <div class="input-group-append label-right" v-if="rightLabel">
          {{ rightLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '../../tooltip/Tooltip'
import { formatNumber } from '@/filters'
import Checkbox from '@/components/form/Checkbox'
export default {
  name: 'Input',
  props: {
    defaultInputValue: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    tooltip: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    showCounter: {
      type: Boolean,
      default: false
    },
    showChangeCheckbox: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableFormGroup: {
      type: Boolean,
      default: false
    },
    value: {},
    valueSuffix: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 255
    },
    maxAvailableLength: {
      type: Number,
      default: 255
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: 1
    },
    rightLabel: {
      type: String,
      default: ''
    },
    formatAsNumber: {
      type: Boolean,
      default: false
    },
    magnifier: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    showMaxLength: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isInputActive: false,
      changeCheckbox: false
    }
  },
  components: {
    Checkbox,
    appTooltip: Tooltip
  },
  computed: {
    displayedValue: {
      get: function () {
        if (this.defaultInputValue.length) {
          return this.defaultInputValue
        }
        if (this.formatAsNumber) {
          if (this.isInputActive) {
            return this.value.toString()
          } else {
            return formatNumber(this.value)
          }
        }
        return this.value
      }
    },
    showCheckmark () {
      return this.inputType === 'url' && !this.error && this.value
    }
  },
  methods: {
    handleChangeCheckbox () {
      if (this.showChangeCheckbox) {
        this.changeCheckbox = true
        this.$emit('change-checked', true)
      } else {
        this.$emit('change-checked')
      }
    },
    handleMovingCursor (input) {
      if (this.type !== 'number') {
        // save cursor current position
        const cursorPosition = input.selectionStart
        // After updating the value, the original position of the cursor is loaded
        // so that trim a spaces does not cause the cursor to move to the end of the text
        this.$nextTick(() => {
          input.selectionStart = input.selectionEnd = cursorPosition
        })
      }
    },
    validate (value) {
      if (this.max) {
        return this.validateMax(value)
      }
      return value
    },
    validateMax (value) {
      if (value > this.max) {
        return this.max
      }
      return value
    },
    handleInput (event) {
      this.handleChangeCheckbox()
      const input = event.target
      this.$emit('input', this.validate(input.value))
      this.handleMovingCursor(input)
    }
  }
}
</script>

<style scoped lang="scss">
  .input-area {
    &__text-error {
      color: #FF3455;
    }
    &__label,
    &__input {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    &__label {
      color: #8A96AC;
      &--change-checkbox {
        padding-left: rem(25px);
      }
    }
    &__change-checkbox {
      padding-top: rem(8px);
    }
    &__input {
      width: 100%;
      display: block;
      padding: 0 rem(10px);
      height: rem(40px) !important;
      border-radius: rem(6px);
      border: solid 1px #d1dbe4;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      &--enabled {
        background: #fff;
      }
      &--error {
        border: 1px solid #FF3455;
      }
    }
    &__group {
      position: relative;
      &--counter {
        display: grid;
        grid-template-columns: auto max-content;
      }
      &--change-checkbox {
        display: grid;
        grid-template-columns: max-content auto;
      }
      &--label-right {
        input {
          padding-right: 2rem;
        }
      }
    }
    &__check {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &__check-icon {
      font-size: rem(23px);
      color: #2FBA4D;
    }
  }
  .input-group-append {
    position: relative;
    &__label {
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 400;
      color: #8A96AC;
    }
  }
  .label-right {
    position: absolute;
    top: rem(8px);
    right: 1rem;
    z-index: 1;
  }
  .magnifier-icon {
    position: absolute;
    top: 13px;
    left: 8px;
    z-index: 99;
    color: #bbbaba;
  }
  .magnifier-input {
    padding-left: 30px;
  }
</style>
<style lang="scss">
  .input-group-text {
    &--error {
      border-color: #FF3455
    }
  }
</style>
