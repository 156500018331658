export default [
  {
    id: 'dashboard',
    isAllowedToDisplay: ['dashboard'],
    subMenu: false,
    displayName: 'menu.dashboard',
    routeName: 'dashboard',
    nestedListItems: []
  },
  {
    id: 'listOfArticles',
    isAllowedToDisplay: ['article_list'],
    subMenu: false,
    displayName: 'menu.articles',
    routeName: '',
    nestedListItems: []
  },
  {
    id: 'homepage',
    isAllowedToDisplay: ['contentBlockItem_list', 'hp_topic_offer_list'],
    subMenu: false,
    displayName: 'menu.hp',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.content_block_items',
        routeName: 'contentBlockItem_list',
        dataTest: 'content_block_items'
      },
      {
        displayName: 'menu.hp_topic_offer',
        routeName: 'hp_topic_offer_list',
        dataTest: 'hp_topic_offer'
      }
    ]
  },
  {
    id: 'dam',
    isAllowedToDisplay: ['dam_list'],
    subMenu: false,
    displayName: 'menu.dam',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.dam_images',
        routeName: 'dam_list',
        dataTest: 'image'
      }
    ]
  },
  {
    id: 'video',
    isAllowedToDisplay: ['video_list'],
    subMenu: false,
    displayName: 'menu.video',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.video_list',
        routeName: 'video_list',
        dataTest: 'video_list'
      }
    ]
  },
  {
    id: 'modules',
    isAllowedToDisplay: [
      'infobox_list', 'poll_list', 'quiz_list',
      'gallery_list', 'serials_list', 'topicsAndSpecials_list',
      'commonTags_list', 'geneeaTags_list', 'editorialTags',
      'hottopics_list', 'breakingnews_list', 'news_of_the_day_list',
      'embedded_application_list'
    ],
    subMenu: false,
    displayName: 'menu.modules',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.infoboxes',
        routeName: 'infobox_list',
        dataTest: 'infoboxes'
      },
      {
        displayName: 'menu.polls',
        routeName: 'poll_list',
        dataTest: 'polls'
      },
      {
        displayName: 'menu.quizzes',
        routeName: 'quiz_list',
        dataTest: 'quizzes'
      },
      {
        displayName: 'menu.galleries',
        routeName: 'gallery_list',
        dataTest: 'galleries'
      },
      {
        displayName: 'menu.serials',
        routeName: 'serials_list',
        dataTest: 'serials',
        featureName: 'newTagManager'
      },
      {
        displayName: 'menu.commonTags',
        routeName: 'commonTags_list',
        dataTest: 'commonTags',
        featureName: 'newTagManager'
      },
      {
        displayName: 'menu.themeSpecialAndSeries',
        routeName: 'themeSpecialAndSeries_list',
        dataTest: 'themeSpecialAndSeries',
        featureName: 'newTagManager'
      },
      {
        displayName: 'menu.hottopics',
        routeName: 'hottopics_list',
        dataTest: 'hottopics'
      },
      {
        displayName: 'menu.breakingnews',
        routeName: 'breakingnews_list',
        dataTest: 'breakingnews'
      },
      {
        displayName: 'menu.right_now',
        routeName: 'news_of_the_day_list',
        dataTest: 'right_now'
      },
      {
        displayName: 'menu.embedded_application',
        routeName: 'embedded_application_list',
        dataTest: 'embedded_application'
      }
    ]
  },
  {
    id: 'articleOffers',
    isAllowedToDisplay: ['topicOffer_list'],
    displayName: 'menu.article_offer',
    subMenu: false,
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.topicOffer',
        routeName: 'topicOffer_list',
        dataTest: 'topicOffer'
      }
    ]
  },
  {
    id: 'system',
    isAllowedToDisplay: [
      'author_list', 'source_list', 'otherFunction_list', 'contentBlock_list',
      'pollBlock_list', 'site_list', 'rubric_list', 'app_performance',
      'scale_list', 'recipe_category_list', 'department_list',
      'category_list', 'tag_list', 'safetyTopic_list', 'redirect_list', 'eshopFeature_list',
      'printTitle_list', 'articleHistory_list', 'tasrNewsCategory_list', 'recipe_list',
      'permissionGroup_list', 'beUser_list'
    ],
    subMenu: false,
    displayName: 'menu.system',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'menu.permission_groups',
        routeName: 'permissionGroup_list'
      },
      {
        displayName: 'menu.beUsers',
        routeName: 'beUser_list',
        dataTest: 'beUsers'
      },
      {
        displayName: 'menu.authors',
        routeName: 'author_list',
        dataTest: 'authors'
      },
      {
        displayName: 'menu.sources',
        routeName: 'source_list',
        dataTest: 'sources'
      },
      {
        displayName: 'menu.otherFunctions',
        routeName: 'otherFunction_list',
        dataTest: 'otherFunctions'
      },
      {
        displayName: 'menu.content_blocks',
        routeName: 'contentBlock_list',
        dataTest: 'content_blocks'
      },
      {
        displayName: 'menu.sites',
        routeName: 'site_list',
        dataTest: 'sites'
      },
      {
        displayName: 'menu.rubrics',
        routeName: 'rubric_list',
        dataTest: 'rubrics'
      },
      {
        displayName: 'menu.categories',
        routeName: 'category_list',
        dataTest: 'categories'
      },
      {
        displayName: 'menu.safetyTopics',
        routeName: 'safetyTopic_list',
        dataTest: 'safetyTopics'
      },
      {
        displayName: 'menu.redirects',
        routeName: 'redirect_list',
        dataTest: 'redirects',
        featureName: 'redirect'
      },
      {
        displayName: 'menu.articleHistory',
        routeName: 'articleHistory_list',
        dataTest: 'articleHistory'
      },
      {
        displayName: 'menu.notificationServiceNotifications',
        routeName: 'notification_list'
      }
    ]
  },
  {
    id: 'language',
    isAllowedToDisplay: true,
    subMenu: false,
    displayName: 'menu.language',
    routeName: '',
    nestedListItems: [
      {
        displayName: 'Czech',
        routeName: '',
        dataTest: 'Czech'
      },
      {
        displayName: 'English',
        routeName: '',
        dataTest: 'English'
      },
      {
        displayName: 'Slovak',
        routeName: '',
        dataTest: 'Slovak'
      }
    ]
  },
  {
    id: 'videoTutorial',
    isAllowedToDisplay: true,
    subMenu: false,
    displayName: 'menu.videoTutorial',
    routeName: 'help_video_list',
    nestedListItems: []
  }
]
