import CoreApi from '../../api/core'
import AuthorModel from '@/model/AuthorModel.js'
import AuthorModelVlm from '@/model/AuthorModelVlm.js'
import AuthorFilterService from '../../services/author/AuthorFilterService'
import Config from '../../config'

const API_NAME = '/author'
const LIMIT = 2000

const state = {
  error: null,
  detail: Config.isVlm() ? AuthorModelVlm : AuthorModel,
  list: [],
  all: [],
  searchedAuthors: [],
  totalCount: 0,
  page: 1,
  filter: {
    id: '',
    name: '',
    originName: '',
    defaultSite: '0'
  },
  authorImage: null,
  runningFetches: {}, // site IDs for which an author fetch is currently running
  doneFetches: {} // site IDs for which an author fetch has been already done
}

const mutations = {
  storeSearchedAuthorsByTitle (state, response) {
    state.searchedAuthors = response.data.data
  },
  storeAuthorsById (state, response) {
    state.searchedAuthors = response.data
  },
  setNewAuthorsBySite (state, newAuthors) {
    const newAuthorsIds = newAuthors.reduce((acc, author) => {
      acc[author.id] = true
      return acc
    }, {})
    state.all = state.all.filter(author => !newAuthorsIds[author.id]) // removing duplicates
    state.all = state.all.concat(newAuthors)
  },
  /**
   * @deprecated Use setNewAuthorsBySite instead. Or re-write the whole lazy-loading process in fetchAuthorsBySiteId.
   */
  setAll () {
    throw Error('Never set "all" here. Use setNewAuthorsBySite instead.')
  },
  addDoneFetch (state, siteId) {
    state.doneFetches[siteId] = true
  },
  addRunningFetch (state, siteId) {
    state.runningFetches[siteId] = true
  },
  removeRunningFetch (state, siteId) {
    delete state.runningFetches[siteId]
  },
  storeList (state, responseData) {
    state.list = responseData.data
    state.totalCount = responseData.totalCount
  },
  storeDetail (state, responseData) {
    state.detail = responseData
    state.detail.description = state.detail.description ?? ''
    state.detail.completeProfile = state.detail.completeProfile ?? ''
  },
  setPage (state, page) {
    state.page = page
  },
  setError (state, message) {
    state.error = message
  },
  setFilter (state, filter) {
    state.filter = filter
  },
  resetFilter (state) {
    state.filter = {
      id: '',
      name: '',
      originName: '',
      firstName: '',
      lastName: '',
      defaultSite: '0'
    }
  },
  storeAuthorImage (state, authorImage) {
    state.authorImage = authorImage
  }
}

const actions = {
  async loadAuthorsByIds ({ commit }, ids) {
    if (ids?.length > 0) {
      try {
        const response = await CoreApi().get(`${API_NAME}?filter_in[id]=${ids}`)
        commit('storeAuthorsById', response.data)
      } catch (e) {
        console.error(e)
      }
    }
  },
  async fetchSearchedByTitle ({ commit }, { query }) {
    if (query) {
      await CoreApi().get(`${API_NAME}?filter_contains[name]=${query}`)
        .then(res => {
          commit('storeSearchedAuthorsByTitle', { data: res.data, totalCount: 0 })
        })
        .catch(error => console.error(error))
    }
  },
  async fetchAuthorsBySiteId ({ state, commit, rootGetters }, siteId) {
    // Custom Sleep Function
    const waitForRunningFetch = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))
    const authorsAreFetching = () => Boolean(state.runningFetches[siteId])
    const authorsAlreadyFetched = () => Boolean(state.doneFetches[siteId])

    if (siteId) {
      // this prevents from multiple requests running for the same siteId
      while (authorsAreFetching()) {
        await waitForRunningFetch(500)
        if (authorsAlreadyFetched()) {
          break
        }
      }

      if (!authorsAlreadyFetched()) {
        commit('addRunningFetch', siteId)
        const siteFilter = rootGetters.vlm ? `&filter_custom[multiSites]=${siteId}` : `&filter_eq[defaultSite]=${siteId}`
        const url = `${API_NAME}?limit=${LIMIT}${siteFilter}`
        await CoreApi(60).get(url)
          .then(res => {
            commit('setNewAuthorsBySite', res.data.data)
            commit('addDoneFetch', siteId)
          })
          .catch(error => console.error(error))
          .finally(() => commit('removeRunningFetch', siteId))
      }
    }
  },
  fetch (store) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.page * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = API_NAME +
      '?limit=' + Config.defaults.list.limit +
      '&offset=' + offset +
      '&order[id]=desc' + AuthorFilterService.buildFilterQuery(store.state.filter)
    CoreApi().get(url)
      .then(res => {
        store.commit('storeList', res.data)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
      .catch(error => {
        console.error(error)
        store.commit('TOGGLE_LOADING', null, { root: true })
      })
  },
  async fetchOne (store, id) {
    await CoreApi().get(API_NAME + '/' + id)
      .then(response => {
        store.commit('storeAuthorImage', null)
        store.commit('storeDetail', response.data)
        if (response.data.image) {
          store.dispatch('fetchImage', response.data.image)
        }
      })
      .catch(error => console.error(error))
  },
  async create (store, record) {
    return await CoreApi().post(API_NAME, JSON.stringify(record))
      .then(response => {
        if (response.status === 201) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async update (store, record) {
    return await CoreApi().put(API_NAME + '/' + record.id, JSON.stringify(record))
      .then(response => {
        if (response.status === 200) {
          store.commit('storeDetail', response.data)
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data.error)
        }
      })
  },
  async deleteRecord (store, record) {
    return await CoreApi().delete(API_NAME + '/' + record.id)
      .then(response => {
        if (response.status === 204) {
          store.commit('setError', null)
        } else {
          store.commit('setError', 'Error')
        }
      })
      .catch(error => {
        if (error.response.status === 500) {
          store.commit('setError', error.response.status)
        } else {
          store.commit('setError', error.response.data)
        }
      })
  },
  async fetchImage (store, id) {
    await CoreApi().get('/image/' + id)
      .then(response => {
        store.commit('storeAuthorImage', response.data)
      })
      .catch(error => console.error(error))
  }
}

const getters = {
  detail (state) {
    return state.detail
  },
  list (state) {
    return state.list
  },
  all (state) {
    return state.all
  },
  searchedByTitle (state) {
    return state.searchedAuthors
  },
  totalCount (state) {
    return state.totalCount
  },
  page (state) {
    return state.page
  },
  error (state) {
    return state.error
  },
  filter (state) {
    return state.filter
  },
  authorByName (state) {
    return id => state.all.find(author => {
      if (id === author.name) {
        return author
      }
    })
  },
  authorImage (state) {
    return state.authorImage
  },
  allBySite (state, getters, rootState, rootGetters) {
    return siteId => state.all.filter(author => {
      if (rootGetters.vlm) {
        return (author.defaultSite === siteId || author.siteIds?.includes(siteId)) &&
          (author.typeAuthor || author.typeEditor || author.typeSportEditor || author.typeReaderReporter)
      }
      return author.defaultSite === siteId
    })
  },
  getAuthorCustomName (state, getters, rootState, rootGetters) {
    return author => {
      if (rootGetters.vlm) {
        const authorName = author.name ? `${author.name} ${author.originName}` : author.originName
        return author.customText ? author.customText : authorName
      }
      return author.name
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
