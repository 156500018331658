import CoreApi from '@/api/core'
import Config from '@/config'
import SpecialFilterService from '@/services/topicsAndSpecials/specialFilterService'
import TopicFilterService from '@/services/topicsAndSpecials/topicFilterService'
import SeriesFilterService from '@/services/topicsAndSpecials/seriesFilterService'

const SPECIAL_API_NAME = '/SpecialArticleGroup'
const THEME_API_NAME = '/Theme'
const SERIES_API_NAME = '/Series'

function handleApiError (store, error) {
  if (error.response && error.response.status === 400) {
    if (error.response.data.error.includes('already exists')) {
      store.commit('SET_FORM_ERRORS', { name: error.response.data.error, type: 'duplicate_name' })
    }
  } else {
    store.commit('SET_ERROR', error)
  }
  store.commit('TOGGLE_LOADING', null, { root: true })
  throw error
}

const state = {
  error: null,
  formErrors: {},
  // * special *
  specialList: [],
  specialDetail: {},
  specialTotalCount: 0,
  specialPage: 1,
  specialFilterQuery: '',
  specialFilter: {
    id: '',
    name: '',
    isActive: null,
    EntityUuids: []
  },
  // * theme *
  themeList: [],
  themeDetail: {},
  themeTotalCount: 0,
  themePage: 1,
  themeFilterQuery: '',
  themeFilter: {
    id: '',
    name: '',
    isActive: null,
    EntityUuids: []
  },
  // * series *
  seriesList: [],
  seriesDetail: {},
  seriesTotalCount: 0,
  seriesPage: 1,
  seriesFilterQuery: '',
  seriesFilter: {
    id: '',
    name: '',
    isActive: null,
    EntityUuids: []
  }
}

const mutations = {
  // -- SPECIAL --
  STORE_SPECIAL_LIST (state, responseData) {
    state.specialList = responseData.data
    state.specialTotalCount = responseData.totalCount
  },
  STORE_SPECIAL_DETAIL (state, responseData) {
    state.specialDetail = responseData
  },
  SET_SPECIAL_PAGE (state, page) {
    state.specialPage = page
    state.themePage = 1
    state.seriesPage = 1
  },
  SET_SPECIAL_FILTER (state, filter) {
    state.specialFilter = filter
  },
  // -- THEME --
  STORE_THEME_LIST (state, responseData) {
    state.themeList = responseData.data
    state.themeTotalCount = responseData.totalCount
  },
  STORE_THEME_DETAIL (state, responseData) {
    state.themeDetail = responseData
  },
  SET_THEME_PAGE (state, page) {
    state.themePage = page
    state.specialPage = 1
    state.seriesPage = 1
  },
  SET_THEME_FILTER (state, filter) {
    state.themeFilter = filter
  },
  // -- SERIES --
  STORE_SERIES_LIST (state, responseData) {
    state.seriesList = responseData.data
    state.seriesTotalCount = responseData.totalCount
  },
  STORE_SERIES_DETAIL (state, responseData) {
    state.seriesDetail = responseData
  },
  SET_SERIES_PAGE (state, page) {
    state.seriesPage = page
    state.specialPage = 1
    state.themePage = 1
  },
  SET_SERIES_FILTER (state, filter) {
    state.seriesFilter = filter
  },
  // -- COMMON --
  SET_ERROR (state, message) {
    state.error = message
  },
  SET_FORM_ERRORS (state, errors) {
    state.formErrors = errors
  }
}

const actions = {
  // ---- SPECIAL ----
  async fetchSpecialList (store, { fetchAll = false } = {}) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.specialPage * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = SPECIAL_API_NAME +
      '?limit=' + (fetchAll ? 500 : Config.defaults.list.limit) +
      '&offset=' + offset +
      '&order[name]=asc' + SpecialFilterService.buildFilterQuery(store.state.specialFilter)

    try {
      const res = await CoreApi().get(url)
      store.commit('STORE_SPECIAL_LIST', res.data)
      store.commit('TOGGLE_LOADING', null, { root: true })
      store.commit('SET_ERROR', null)
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('SET_ERROR', error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async createSpecial (store, specialData) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().post(SPECIAL_API_NAME, specialData)
      store.commit('STORE_SPECIAL_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async fetchOneSpecial (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = SPECIAL_API_NAME + '/' + id
      const res = await CoreApi().get(url)
      store.commit('STORE_SPECIAL_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('SET_ERROR', error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async updateSpecial (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = SPECIAL_API_NAME + '/' + payload.id
      const res = await CoreApi().put(url, payload)
      store.commit('STORE_SPECIAL_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async deleteSpecial (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().delete(`${SPECIAL_API_NAME}/${payload.id}`)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  // ---- THEME ----
  async fetchThemeList (store, { fetchAll = false } = {}) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.themePage * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = THEME_API_NAME +
      '?limit=' + (fetchAll ? 500 : Config.defaults.list.limit) +
      '&offset=' + offset +
      '&order[name]=asc' + TopicFilterService.buildFilterQuery(store.state.themeFilter)

    try {
      const res = await CoreApi().get(url)
      store.commit('STORE_THEME_LIST', res.data)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async createTheme (store, themeData) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().post(THEME_API_NAME, themeData)
      store.commit('STORE_THEME_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async fetchOneTheme (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = THEME_API_NAME + '/' + id
      const res = await CoreApi().get(url)
      store.commit('STORE_THEME_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('SET_ERROR', error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async updateTheme (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = THEME_API_NAME + '/' + payload.id
      const res = await CoreApi().put(url, payload)
      store.commit('STORE_THEME_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async deleteTheme (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().delete(`${THEME_API_NAME}/${payload.id}`)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  // ---- SERIES ----
  async fetchSeriesList (store, { fetchAll = false } = {}) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    const offset = (store.state.seriesPage * Config.defaults.list.limit) - Config.defaults.list.limit
    const url = SERIES_API_NAME +
      '?limit=' + (fetchAll ? 500 : Config.defaults.list.limit) +
      '&offset=' + offset +
      '&order[name]=asc' + SeriesFilterService.buildFilterQuery(store.state.seriesFilter)

    try {
      const res = await CoreApi().get(url)
      store.commit('STORE_SERIES_LIST', res.data)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async createSeries (store, seriesData) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().post(SERIES_API_NAME, seriesData)
      store.commit('STORE_SERIES_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async fetchOneSeries (store, id) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = SERIES_API_NAME + '/' + id
      const res = await CoreApi().get(url)
      store.commit('STORE_SERIES_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('SET_ERROR', error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  async updateSeries (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const url = SERIES_API_NAME + '/' + payload.id
      const res = await CoreApi().put(url, payload)
      store.commit('STORE_SERIES_DETAIL', res.data)
      store.commit('SET_ERROR', null)
      store.commit('SET_FORM_ERRORS', {})
      store.commit('TOGGLE_LOADING', null, { root: true })
    } catch (error) {
      handleApiError(store, error)
    }
  },
  async deleteSeries (store, payload) {
    store.commit('TOGGLE_LOADING', null, { root: true })
    try {
      const res = await CoreApi().delete(`${SERIES_API_NAME}/${payload.id}`)
      store.commit('TOGGLE_LOADING', null, { root: true })
      return res.data
    } catch (error) {
      console.error(error)
      store.commit('TOGGLE_LOADING', null, { root: true })
      throw error
    }
  },
  // ---- COMMON ----
  setCurrentModule ({ commit }, module) {
    commit('SET_CURRENT_MODULE', module)
  }
}

const getters = {
  // Getters for Special
  specialList: state => state.specialList,
  specialDetail: state => state.specialDetail,
  specialTotalCount: state => state.specialTotalCount,
  specialPage: state => state.specialPage,
  specialFilter: state => state.specialFilter,

  // Getters for Topic
  themeList: state => state.themeList,
  themeDetail: state => state.themeDetail,
  themeTotalCount: state => state.themeTotalCount,
  themePage: state => state.themePage,
  themeFilter: state => state.themeFilter,

  // Getters for Series
  seriesList: state => state.seriesList,
  seriesDetail: state => state.seriesDetail,
  seriesTotalCount: state => state.seriesTotalCount,
  seriesPage: state => state.seriesPage,
  seriesFilter: state => state.seriesFilter,

  // Common
  error: state => state.error,
  formErrors: state => state.formErrors
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
