export default {
  header_1: 'NAVIGÁCIA',
  system: 'Systém',
  dashboard: 'Realtime štatistiky',
  dashboard_old: 'Realtime štatistiky (old)',
  articles: 'Zoznam článkov',
  dam: 'DAM',
  dam_images: 'Obrázky',
  dam_pdf: 'Dokumenty',
  video: 'Video',
  video_list: 'Videa',
  video_list_old: 'Videa(old)',
  video_management: 'Video management',
  video_show_list: 'PLUS TV relácie',
  video_category_list: 'PLUS TV kategórie',
  video_stats: 'Štatistiky videí',
  video_kpi_reports: 'Report uploadnutých videí',
  don: 'Sponzorované články',
  don_campaign_management: 'Manažment kampaní',
  don_campaign_management_old: 'Manažment kampaní (old)',
  don_campaign_predictions: 'Vývoj kampaní',
  infoboxes: 'Infoboxy',
  serials: 'Seriály',
  themeSpecialAndSeries: 'Témy, špeciály a seriály',
  tags: 'Tagy',
  commonTags: 'Tagy',
  editorialTags: 'Redakčné tagy',
  geneeaTags: 'Geneea tagy',
  jokes: 'Vtipy',
  quotes: 'Myšlienka týždňa',
  categories: 'Kategórie',
  safetyTopics: 'Safety topics',
  polls: 'Ankety',
  polls_on_homepage: 'Ankety na Homepage',
  like_dislike: 'Páči nepáči',
  quizzes: 'Kvízy',
  oneQuestion: 'Jedna otázka',
  content_blocks: 'Content Blocky',
  poll_blocks: 'Poll Blocks',
  content_block_items: 'HP management',
  content_block_items_old: 'HP management(old)',
  rubrics: 'Rubriky',
  authors: 'Autori',
  sources: 'Zdroje',
  otherFunctions: 'Ďalšie funkcie',
  redirects: 'Presmerovania',
  modules: 'Moduly',
  statistics: 'Štatistiky',
  historical_dashboard: 'Štatistiky produktu',
  beUsers: 'Backend Užívatelia',
  performance: 'Editor výkonnosť',
  beUserKpi_list: 'Editor KPI',
  kpi_comparison: 'Porovnanie KPI',
  performance_product: 'Výkonnosť produktu',
  performance_department: 'Výkonnosť oddelenia',
  historical_performance: 'Historická výkonnosť',
  edonProduct_list: 'Produkty',
  scale_list: 'Scale Editor',
  feUsers: 'Používatelia',
  feUsersDeliveryAddresses: 'Doručovacie adresy',
  feUsersBillingAddresses: 'Fakturačné adresy',
  feUsersArticles: 'Články',
  feUsersPollVotes: 'Ankety',
  all_articles: 'Všetky články',
  sites: 'Sites',
  features: 'Features',
  articleHistory: 'Article history',
  sport24: 'Športové tabuľky',
  userLoginAttempts: 'User Login Attempts',
  notificationServiceNotifications: 'Notifikácie',
  tasrNews: 'TASR',
  tasrNewsCategory: 'TASR news category',
  topicOffer: 'Ponuka obsahu',
  article_offer: 'Ponuky článkov',
  print: 'Print',
  printPublications: 'Printové publikácie',
  printTitles: 'Printové tituly',
  printArticles: 'Printové články',
  topic: 'Témy',
  eshop: 'Eshop',
  eshopSubscriptionProducts: 'Produkty',
  eshopTopProductSetting: 'TOP Produkty',
  eshopSubscriptionOrders: 'Objednávky',
  eshopHistory: 'História',
  eshopSubscriptionPayments: 'Platby',
  eshopSubscriptions: 'Predplatné',
  eshopSubscribers: 'Predplatitelia',
  eshopFeatures: 'Eshop features',
  eshopDashboard: 'Dashboard',
  tests: 'Testy',
  cars: 'Vozidlá',
  restaurants: 'Reštaurácie',
  hotels: 'Hotely',
  departments: 'Oddelenia',
  app_performance: 'App Performance',
  video_daily_report: 'Report redakčných videí',
  video_published_report: 'Report publikovaných videí',
  performance_weights: 'Nastavenie váh',
  permission_groups: 'Skupiny oprávnení',
  kpi_settings: 'Nastavenie KPI',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historické NMH KPI',
  kpi_nmh_settings: 'NMH KPI nastavenia',
  recipes: 'Recepty',
  recipe_categories: 'Kategórie receptu',
  hp_topic_offer: 'HP ponuka článkov',
  hp_topic_offer_old: 'HP ponuka článkov(old)',
  hp: 'Homepage',
  wood_wing: 'WoodWing',
  videoTutorial: 'Video návod',
  language: 'Nastavenie jazyka',
  galleries: 'Galérie',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  right_now: 'Práve teraz',
  embedded_application: 'VLM aplikácie'
}
